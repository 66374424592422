import Logo from "../images/logo.png";
import { Container, Row, Col } from 'react-grid-system';
import React, { Component, Suspense } from 'react';
import Footer from "./Footer";
import Loader from "./Loader";
import { Link } from 'react-router-dom';

class BaseLayout extends Component {
      
    render() {

        return (
            <Container fluid className="container-master">
                <Container fluid>
                    <Row className="header" >
                        <Col xs={12} md={12} >
                            <Container>
                                <Row className="inner-header">
                                    <Col xs={12} md={12} className="row-logo" >
                                        <Link to={`${process.env.PUBLIC_URL}/`}>
                                            <img src={Logo} alt='' />
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <Col xs={12} md={12}>
                            <main className="main-page">
                                <Suspense fallback={<Loader />}>
                                    {this.props.children}
                                </Suspense>
                            </main>
                        </Col>
                    </Row>
                </Container>
                <Container fluid style={{marginTop: "30px", position: "relative", width: "100%", bottom: "0"}}>
                    <Row className="footer">
                        <Footer />
                    </Row>
                </Container>
            </Container>
        );
    }
}

export default BaseLayout;
