import { BrowserRouter } from "react-router-dom";
import React, { Component } from "react";
import Routes from './Routes';

class App extends Component {
  render() {

    return (
        <BrowserRouter>
          <Routes  />
        </BrowserRouter>
    );
  }
}

export default App;
