import { Container, Row, Col } from 'react-grid-system';
import { GetApi, PostApi } from '../services/ApiServices';
import { FaUser, FaKey } from 'react-icons/fa';
import { Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class RegisterPage extends Component {

  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
      errors: '',
      textBtn: 'Entrar',
      disabled: false,
      showError: false
    };

    this.sign_in = this.sign_in.bind(this);
    this.onChange = this.onChange.bind(this);
		window.scrollTo(0, 0);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async sign_in(redirect) {
    if (this.state.email && this.state.password) {
      const { email, password } = this.state
      this.setState({
        disabled: true,
        textBtn: 'Aguarde'
      })
      const data = await PostApi('login', { email, password  })
      
      if (data.access_token) {
        const user =  await GetApi('user', data.access_token)
        if (user) {
          sessionStorage.setItem('menosumlixo_access_token', (data.access_token));
          sessionStorage.setItem('menosumlixo_client', JSON.stringify(user));
          if(redirect === 1) {
            window.location.href = '/order/'+this.props.match.params.orderID+'/'+this.props.match.params.token;
          } else {
            window.location.href = `${process.env.PUBLIC_URL}/`;
          }
        } else {
          this.setState({ errors: "Ocorreu um erro! Entre em contato com o administrador.", showError: true, disabled: false, textBtn: 'Entrar' })
          setTimeout(() => {
            this.toggleHidden()
          }, 5000)
        }  
      } else {
        this.setState({ errors: "Usuário não encontrado ou senha incorreta", showError: true, disabled: false, textBtn: 'Entrar' })
        setTimeout(() => {
          this.toggleHidden()
        }, 5000)
      }
    } else {
      this.setState({ errors: "Os campos são obrigatórios", showError: true, disabled: false, textBtn: 'Entrar' })
      setTimeout(() => {
        this.toggleHidden()
      }, 5000)
    }
  }

  toggleHidden () {
    this.setState({
      showError: !this.state.showError
    })
  }

  render() {
    
    if (sessionStorage.menosumlixo_access_token && this.props.match.params.orderID && this.props.match.params.token) {
      return (
        <Redirect to={'/order/'+this.props.match.params.orderID+'/'+this.props.match.params.token} />
      )
    } else if (sessionStorage.menosumlixo_access_token) {
      return (
        <Redirect to={'/'} />
      )
    } else {
      return (
        <Container>
          <Row>
            <Col xs={12} offset={{ md: 3 }} md={6}>
              <div className="area-box area-login">
                <Row>
                  <Col xs={12} md={12}>
                    <label className="label-login"><FaUser /><span>E-mail</span>
                      <input
                        type="text"
                        name="email"
                        onChange={this.onChange}
                        required
                      />
                    </label>
                  </Col>
                  <Col xs={12} md={12}>
                    <label className="label-login"><FaKey /><span>Senha</span>
                      <input
                        type="password"
                        name="password"
                        onChange={this.onChange}
                        required
                      />
                    </label>
                  </Col>
                  {this.props.match.params.orderID && this.props.match.params.token ? (
                    <Col xs={12} md={12} className="row-btn-submit">
                    <input type="submit" value={this.state.textBtn} className="btn-submit"
                      onClick={() => this.sign_in(1)}
                      disabled={this.state.disabled}
                      />
                      <Link to="welcome" className="btn-submit btn-logout" style={{ marginTop: "-10px", marginLeft: "10px"}}>Voltar</Link> 
                    </Col>
                  ) : (
                    <Col xs={12} md={12} className="row-btn-submit">
                    <input type="submit" value={this.state.textBtn} className="btn-submit"
                      onClick={() => this.sign_in(0)}
                      disabled={this.state.disabled}
                      />
                      <Link to="welcome" className="btn-submit btn-logout" style={{ marginTop: "-10px", marginLeft: "10px"}}>Voltar</Link> 
                    </Col>
                  )}
                  <Col xs={12} md={12} className="row-remember-password">
                    <Link className="remember-password" to="/remember-password">Esqueceu sua senha?</Link>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Col xs={12} md={12} className="row-btn-submit">

            {this.state.errors !== "" && this.state.showError && (<div id="invalid-feedback" className="invalid-feedback" onClick={this.toggleHidden.bind(this)}>{this.state.errors}<span>x</span></div>)}
            
          </Col>
        </Container>
      );
    }
  }
}

export default RegisterPage;