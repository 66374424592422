import { PostApi , DeleteApi, PutApi } from '../services/ApiServices';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';

class OrderValidatePage extends Component {

	constructor(props) {
		super(props);
	
		this.SessionClient = JSON.parse(sessionStorage.menosumlixo_client);
		this.SessionAccessToken = sessionStorage.menosumlixo_access_token;
		this.sign_out = this.sign_out.bind(this);
		this.invoiceOrder = this.invoiceOrder.bind(this);

		this.state = {
			items: [],
			printing: 0,
			logo_colors: 0,
			same_address: true,
			user_id: this.SessionClient.id,
			type: this.SessionClient.type,
			address_zipcode: this.SessionClient.address_zipcode,
			address_street: this.SessionClient.address_street,
			address_number: this.SessionClient.address_number,
			address_complement: this.SessionClient.address_complement,
			address_neighborhood: this.SessionClient.address_neighborhood,
			address_state: this.SessionClient.address_state,
			address_city: this.SessionClient.address_city,
			errors: '',
      textBtn: 'Confirmar',
      disabled: false,
      showError: false
		}
		window.scrollTo(0, 0);
	}

	async componentDidMount() {
		this.mounted = true;

		if (this.mounted) {
			var order = await PostApi('orders/get', {id: this.props.match.params.orderID, token: this.props.match.params.token})
			
      if (order.length > 0) {
        this.setState({
					order: order,
					items: order[0].items,
          empty: 2
        })
      } else {
        this.setState({
          empty: 1
        })
      }
		}
	}

	async sign_out() {
		await DeleteApi('logout',sessionStorage.menosumlixo_access_token);
		window.location.href = `${process.env.PUBLIC_URL}/`;
	}

	async invoiceOrder() {
		const order = this.state.order[0]
		order.status = 'invoice'
		this.setState({
			disabled: true,
			textBtn: 'Aguarde'
		})
		
		const data = await PutApi('orders/invoice', { order, user_id: this.SessionClient.id }, this.SessionAccessToken)

		if (data.code === 201) {
			this.toTop();
			this.props.history.push({
				pathname: '/order/invoiced',
				order:	1
			}); 
		} else {
			if (data.errors) {
				var keys = Object.keys(data.errors)
				var field = document.getElementsByName(keys[0])
				var message = data.errors[Object.keys(data.errors)[0]]
				field[0].focus();
				this.setState({ errors: message[0], showError: true, disabled: false, textBtn: 'Enviar' })
				setTimeout(() => {
					this.toggleHidden()
				}, 5000)
			}
		}	
  }

  toggleHidden () {
    this.setState({
      showError: !this.state.showError
    })
	}
	
	toTop() {
    window.scrollTo(0, 80);
  }

	render() {
		if (sessionStorage.menosumlixo_access_token) {

			if (this.state.order) {
				var unitary_cup = Number(this.state.order[0].unitary_value.replace(',','.'))+Number(this.state.order[0].unitary_printing_value.replace(',','.'))
			}
			
			return (
				<React.Fragment>
					<Row className="row-order-area">
						<Col xs={12} sm={10} md={10} lg={10} className="area-box form-order">
							<form encType="multipart/form-data">
								{this.state.empty ? (this.state.order && this.state.order.length > 0 ? (
								<Row id='row-step' className='row-step'>
									<Col xs={12} md={12}>
											<h2>Confira seu pedido</h2>
									</Col>
									<Col xs={12} md={12} style={{paddingBottom: "30px",  marginTop: "30px"}}>
									<p style={{textAlign: "center"}}>Veja se as informações estão certinhas e confirme seu pedido no final da página.</p>
									</Col>
									<Col xs={12} md={12} style={{marginBottom: "30px"}}>
									<table width="70%" align="center" className="order-table" cellPadding={0} cellSpacing={0}>
										<thead>
											<tr>
												<th width="50%" align="center">Produto</th>
												<th width="50%" align="center">Quantidade</th>
											</tr>
										</thead>
										<tbody>
											{this.state.items && this.state.items.map((items, i) => {
												return <OrderItem key={i} item={items} id={i} setQuantity={this.setQuantity} />;
											})}
										</tbody>
									</table>
									</Col>
									<Col xs={12} md={12}>
									<table width="100%" cellPadding="5">
										<tbody>
											<tr>
												<th align="right">Quantidade:</th>
												<td>{this.state.order[0].quantity}</td>
											</tr>
											<tr>
												<th width="50%" align="right">Valor unitário:</th>
												<td>R$ {unitary_cup.toFixed(2).toString().replace('.',',')}</td>
											</tr>
											{this.state.order && this.state.order[0].printing !== 0 && (
												<tr>
												<th width="50%" align="right"></th>
												<td>R$ {this.state.order[0].unitary_value} <small>(valor do copo)</small><br />+<br />R$ {this.state.order[0].unitary_printing_value} <small>(valor da personalização)</small></td>
											</tr>
											)}
										</tbody>
									</table>
									<hr />
									<table width="100%" cellPadding="5">
										<tbody>
											<tr>
												<th align="right">Forma de Pagamento:</th>
												<td>
													{this.state.order[0].new === 1 ? (<span>Sinal de 50% em 7 dias a partir do pedido + 50% em 30 dias</span>) : (<span>30 dias a partir do fechamento do pedido</span>) }</td>
											</tr>
											<tr>
												<th width="50%" align="right">Frete:</th>
												<td>Incluso</td>
											</tr>
											<tr>
												<th width="50%" align="right">Entrega:</th>
												<td>Prazo de Entrega médio: até 21 dias </td>
											</tr>
											<tr>
												<th align="right">Impostos:</th>
												<td>Inclusão de 10% de IPI em cima do valor final da nota fiscal (frete + produto)</td>
											</tr>
											<tr>
												<th align="right">Validade:</th>
												<td>Orçamento válido por 30 dias</td>
											</tr>
										</tbody>
									</table>
									</Col>
									<Col xs={12} md={12}>
											<Link to="#" onClick={this.sign_out} className="btn-submit btn-logout">Sair</Link> 
											<input
											className="btn-submit"
											type="button"
											value={this.state.textBtn}
											disabled={this.state.disabled}
											onClick={() => this.invoiceOrder()}
											/>
									</Col>
									<Col xs={12} md={12} className="row-btn-submit">

										{this.state.errors !== "" && this.state.showError && (<div id="invalid-feedback" className="invalid-feedback">{this.state.errors}</div>)}
										
									</Col>
									
								</Row>
								) : (
									<Row>
                    <Col xs={12} md={12}>
                      <p className="text-introduction" style={{textAlign: "center"}}>Pedido inválido ou expirado
                      </p>
                    </Col>
                    <Col xs={12} md={12}>
                      <Link
                      className="btn-submit"
                      to="/">Voltar</Link>
                    </Col>
                  </Row>
								)) : (
									<p style={{textAlign: "center"}}>Carregando...</p>
									
								)}
							</form>
						</Col>
					</Row>
				</React.Fragment>
			);
		} else {
				return (<Redirect to={'/login'} />)
		}
	}
}

class OrderItem extends Component {
	
	constructor(props) {
		super(props);
		this.SessionClient = JSON.parse(sessionStorage.menosumlixo_client);
	}

	render() {
		return(
			<tr>
				<td width="50%" align="center">{this.props.item.name}</td>
				<td width="50%" align="center">{this.props.item.quantity}</td>
			</tr>
		)
	}
}


export default OrderValidatePage;
