import { PostApi, PutApi } from '../services/ApiServices';
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
// import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
// import * as cep from 'cep-promise';

class OrderPage extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangePrinting = this.handleChangePrinting.bind(this);
        this.handleChangeUsedTo = this.handleChangeUsedTo.bind(this);
        this.setQuantity = this.setQuantity.bind(this);
        this.sendOrder = this.sendOrder.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.handleChangeSales = this.handleChangeSales.bind(this);
        this.handleChangeCEP = this.handleChangeCEP.bind(this);

        this.state = {
            name: '',
            cnpj: '00000000000',
            state_subscription: '00000000000',
            no_ss: true,
            email: '',
            sales_channel: '',
            sales_notes: '',
            address_zipcode: '',
            address_street: '---',
            address_number: '0',
            address_complement: '---',
            address_neighborhood: '---',
            address_state: '--',
            address_city: '---',
            person: '',
            phone: '',
            area: '---',
            cargo: '',
            productsT: [],
            productsN: [],
            productsA: [],
            printing: 0,
            used_to: '',
            logo_colors: 0,
            type: '',
            newClient: '',
            errors: '',
            aceite: false,
            textBtn: 'Enviar',
            disabled: false,
            showError: false
        }
        window.scrollTo(0, 0);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChecked(e) {
        this.setState({ [e.target.name]: e.target.checked });
    }

    handleChangeSales(e) {
        this.setState({
            [e.target.name]: e.target.value,
            sales_notes: ""
        });
    }

    handleChangePrinting(e) {
        if (e.target.value === "0") {
            this.setState({
                [e.target.name]: e.target.value,
                logo_colors: ''
            });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleChangeUsedTo(e) {
        if (e.target.value === "0") {
            this.setState({
                [e.target.name]: e.target.value,
            });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    setQuantity(product) {
        if (product.quantity === "0" && this.state.cart) {
            delete this.state.cart[product.id]
            if (Object.entries(this.state.cart).length === 0 && this.state.cart.constructor === Object) {
                delete this.state.cart
                this.setState({
                    ...this.state
                })
            } else {
                this.setState({
                    cart: {
                        ...this.state.cart
                    }
                })
            }
        } else {
            this.setState({
                cart: {
                    ...this.state.cart,
                    [product.id]: product.quantity
                }
            })
        }

    }

    async componentDidMount() {
        this.mounted = true;
        if (this.mounted) {
            const aux = await PostApi('products', {})
            this.setState({
                productsT: aux.translucido,
                productsN: aux.novos,
                productsA: aux.all,
            })
        }
    }

    checkMinColorsQuantity() {
        for (var i = 0; i < Object.values(this.state.cart).length; i++) {
            if (Object.values(this.state.cart)[i] < 10)
                return true
        }

        return false
    }

    checkMultipleQuantity() {
        for (var i = 0; i < Object.values(this.state.cart).length; i++) {
            if (Object.values(this.state.cart)[i] % 10 !== 0)
                return true
        }

        return false
    }

    checkMinQuantity() {
        var quantity = 0

        for (var i = 0; i < Object.values(this.state.cart).length; i++) {
            quantity = quantity + parseInt(Object.values(this.state.cart)[i])
        }

        if (quantity < 40)
            return true

        return false
    }

    handleChangeCEP(e) {

        this.setState({
            address_zipcode: e.target.value
        });

        // if (this.IsCEP(e.target.value)) {
        //     var cepNumber = e.target.value.replace("-", "");
        //     cepNumber = cepNumber.replace(/\./, "");
        //     cep(cepNumber)
        //         .then(data => {
        //             this.setState({
        //                 address_street: data.street,
        //                 address_neighborhood: data.neighborhood,
        //                 address_city: data.city,
        //                 address_state: data.state
        //             });
        //         })
        //         .catch(error => {
        //             this.setState({ errors: "CEP inválido!", showError: true, disabled: false, textBtn: 'Enviar' })
        //             setTimeout(() => {
        //                 this.toggleHidden()
        //             }, 5000)
        //             return false;
        //         });
        // }
    }

    IsCEP(strCEP) {
        // Caso o CEP não esteja nesse formato ele é inválido!
        var objER = /^[0-9]{2}[0-9]{3}-[0-9]{3}$/;

        if (strCEP.length > 0) {
            if (objER.test(strCEP))
                return true;
            else
                return false;
        } else
            return false;
    }

    validarCNPJ(n) {
        var cnpj = n.replace(/[^\d]+/g, '');

        if (cnpj === '') return false;

        if (cnpj.length !== 14 && cnpj.length !== 11)
            return false;

        if (cnpj.length === 14) {
            // Elimina CNPJs invalidos conhecidos
            if (cnpj === "00000000000000" ||
                cnpj === "11111111111111" ||
                cnpj === "22222222222222" ||
                cnpj === "33333333333333" ||
                cnpj === "44444444444444" ||
                cnpj === "55555555555555" ||
                cnpj === "66666666666666" ||
                cnpj === "77777777777777" ||
                cnpj === "88888888888888" ||
                cnpj === "99999999999999")
                return false;

            // Valida DVs
            var tamanho = cnpj.length - 2
            var numeros = cnpj.substring(0, tamanho);
            var digitos = cnpj.substring(tamanho);
            var soma = 0;
            var pos = tamanho - 7;
            for (var i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

            if (resultado !== parseInt(digitos.charAt(0)))
                return false;

            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (i = tamanho; i >= 1; i--) {
                soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
                if (pos < 2)
                    pos = 9;
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado !== parseInt(digitos.charAt(1)))
                return false;

            return true;
        }

        if (cnpj.length === 11) {
            if (cnpj === "00000000000" ||
                cnpj === "11111111111" ||
                cnpj === "22222222222" ||
                cnpj === "33333333333" ||
                cnpj === "44444444444" ||
                cnpj === "55555555555" ||
                cnpj === "66666666666" ||
                cnpj === "77777777777" ||
                cnpj === "88888888888" ||
                cnpj === "99999999999")
                return false;

            // Valida 1o digito	
            let add = 0;
            for (i = 0; i < 9; i++)
                add += parseInt(cnpj.charAt(i)) * (10 - i);
            let rev = 11 - (add % 11);
            if (rev === 10 || rev === 11)
                rev = 0;
            if (rev !== parseInt(cnpj.charAt(9)))
                return false;
            // Valida 2o digito	
            add = 0;
            for (i = 0; i < 10; i++)
                add += parseInt(cnpj.charAt(i)) * (11 - i);
            rev = 11 - (add % 11);
            if (rev === 10 || rev === 11)
                rev = 0;
            if (rev !== parseInt(cnpj.charAt(10)))
                return false;
            return true;

        }

    }

    async sendOrder() {
        let { name, cnpj, state_subscription, email, person, phone, area, cargo, printing, logo_colors, type, cart, newClient, used_to, address_zipcode, address_street, address_number, address_complement, address_neighborhood, address_state, address_city } = this.state

        this.setState({
            disabled: true,
            textBtn: 'Aguarde'
        })


        if (name === '') {
          name = '------';
        // 	this.toTop();
        // 	this.setState({ errors: "Nome da empresa é obrigatório!", showError: true, disabled: false, textBtn: 'Enviar' })
        // 	setTimeout(() => {
        // 		this.toggleHidden()
        // 	}, 5000)
        // 	return false;
        }

        if (email === '') {
            this.toTop();
            this.setState({ errors: "E-mail da empresa é obrigatório!", showError: true, disabled: false, textBtn: 'Enviar' })
            setTimeout(() => {
                this.toggleHidden()
            }, 5000)
            return false;
        }

        // if (!this.validarCNPJ(cnpj)) {
        //     this.toTop();
        //     this.setState({ errors: "CNPJ/CPF inválido!", showError: true, disabled: false, textBtn: 'Enviar' })
        //     setTimeout(() => {
        //         this.toggleHidden()
        //     }, 5000)
        //     return false;
        // }

        // var ie = require("inscricaoestadual");
        // if (!no_ss) {
        //   if (!ie(state_subscription, address_state)) {
        //     if (state_subscription.length < 5 && !no_ss) {
        //       this.toTop();
        //       this.setState({ errors: "Inscrição Estadual inválida!", showError: true, disabled: false, textBtn: 'Enviar' })
        //         setTimeout(() => {
        //           this.toggleHidden()
        //       }, 5000)
        //       return false;
        //     }
        //   } else {
        //     this.setState({ state_subscription: 'ISENTO' })
        //   }
        // }

        if (phone.length !== 15) {
            this.toTop();
            this.setState({ errors: "Telefone inválido", showError: true, disabled: false, textBtn: 'Enviar' })
            setTimeout(() => {
                this.toggleHidden()
            }, 5000)
            return false;
        }

        // if (address_state === "") {
        //     this.toTop();
        //     this.setState({ errors: "Preencha os campos de endereço primeiro", showError: true, disabled: false, textBtn: 'Enviar' })
        //     setTimeout(() => {
        //         this.toggleHidden()
        //     }, 5000)
        //     return false;
        // }

        if (person === '') {
            this.toTop();
            this.setState({ errors: "Responsável é obrigatório!", showError: true, disabled: false, textBtn: 'Enviar' })
            setTimeout(() => {
                this.toggleHidden()
            }, 5000)
            return false;
        }

        // if (area === '') {
        //     this.toTop();
        //     this.setState({ errors: "Área é obrigatório!", showError: true, disabled: false, textBtn: 'Enviar' })
        //     setTimeout(() => {
        //         this.toggleHidden()
        //     }, 5000)
        //     return false;
        // }

        if (cargo === '') {
          cargo = '------'
        // 	this.toTop();
        // 	this.setState({ errors: "Cargo é obrigatório!", showError: true, disabled: false, textBtn: 'Enviar' })
        // 	setTimeout(() => {
        // 		this.toggleHidden()
        // 	}, 5000)
        // 	return false;
        }

        if(address_zipcode === ''){
          address_zipcode = '00000-000'
        }

        if (!this.state.cart) {
            this.toTop();
            this.setState({ errors: "A quantidade mínima é de 40 copos", showError: true, disabled: false, textBtn: 'Enviar' })
            setTimeout(() => {
                this.toggleHidden()
            }, 5000)
        } else if (this.checkMinQuantity()) {
            this.toTop();
            this.setState({ errors: "A quantidade mínima é de 40 copos", showError: true, disabled: false, textBtn: 'Enviar' })
            setTimeout(() => {
                this.toggleHidden()
            }, 5000)
            // } else if (this.checkMinColorsQuantity()) {
            // 	this.toTop();
            // 	this.setState({ errors: "A quantidade mínima para cada cor é de 10 copos", showError: true, disabled: false, textBtn: 'Enviar' })
            // 	setTimeout(() => {
            // 		this.toggleHidden()
            // 	}, 5000)
            // } else if (this.checkMultipleQuantity()) {
            // 	this.toTop();
            // 	this.setState({ errors: "A quantidade por cor deve ser múltipla de 10", showError: true, disabled: false, textBtn: 'Enviar' })
            // 	setTimeout(() => {
            // 		this.toggleHidden()
            // 	}, 5000)
        } else if (this.state.printing === "1" && this.state.logo_colors === 0) {
            this.toTop();
            this.setState({ errors: "Escolha o número de cores do logo", showError: true, disabled: false, textBtn: 'Enviar' })
            var focus = document.getElementsByName('logo_colors')
            focus[0].focus();
            setTimeout(() => {
                this.toggleHidden()
            }, 5000)
        // } else if (this.state.newClient === "") {
        // 	this.setState({ errors: "Escolha se você já é um cliente", showError: true, disabled: false, textBtn: 'Enviar' })
        // 	focus = document.getElementsByName('newClient')
        // 	focus[0].focus();
        // 	setTimeout(() => {
        // 		this.toggleHidden()
        // 	}, 5000)
        } else if (this.state.type === "") {
            this.setState({ errors: "Para quem você quer comprar os Copinhos Menos 1 Lixo?", showError: true, disabled: false, textBtn: 'Enviar' })
            focus = document.getElementsByName('type')
            focus[0].focus();
            setTimeout(() => {
                this.toggleHidden()
            }, 5000)
        // } else if (this.state.used_to === "") {
        // 	this.setState({ errors: "Escolha o motivo para que os Copinhos Menos 1 Lixo serão utilizados", showError: true, disabled: false, textBtn: 'Enviar' })
        // 	focus = document.getElementsByName('used_to')
        // 	focus[0].focus();
        // 	setTimeout(() => {
        // 		this.toggleHidden()
        // 	}, 5000)
        } else if (!this.state.aceite) {
            this.setState({ errors: "Você deve aceitar os termos de nossa Política de Privacidade", showError: true, disabled: false, textBtn: 'Enviar' })
            setTimeout(() => {
                this.toggleHidden()
            }, 5000)
        } else {
            const data = await PutApi('orders/save', { name, cnpj, state_subscription, email, person, phone, area, cargo, printing, logo_colors, type, cart, newClient, used_to, address_zipcode, address_street, address_number, address_complement, address_neighborhood, address_state, address_city })
            if (data.code !== 201) {
                if (data.errors) {
                    var keys = Object.keys(data.errors)
                    var field = document.getElementsByName(keys[0])
                    var message = data.errors[Object.keys(data.errors)[0]]
                    field[0].focus();
                }
                console.log(message, data)
                this.setState({ errors: message[0], showError: true, disabled: false, textBtn: 'Enviar' })
                setTimeout(() => {
                    this.toggleHidden()
                }, 5000)
            } else {
                this.props.history.push({
                    pathname: '/order/finished',
                    order: 1
                });
            }
        }
    }

    toggleHidden() {
        this.setState({
            showError: !this.state.showError
        })
    }

    toTop() {
        window.scrollTo(0, 80);
    }

    render() {
        return (
            <React.Fragment>
                <Row className="row-order-area">
                    <Col xs={12} sm={10} md={10} lg={10} className="area-box form-order">
                        {/*{!this.state.type ? (*/}
                        {/*<Row id='row-step' className='row-step'>
                                <Col xs={12} md={12} style={{textAlign: "center"}}>
                                    <p>&nbsp;</p><p>&nbsp;</p>
                                    <p style={{fontSize: "28px", fontWeight: "100", lineHeight: "38px"}}>Você é uma empresa ou uma revenda?</p>
                                </Col>
                                <Col xs={12} md={12} style={{textAlign: "center", marginBottom: "40px"}}>
                                    <Row>
                                    <Col xs={6} md={6} style={{textAlign: "right"}}>
                                        <input
                                        className="btn-submit"
                                        type="button"
                                        value="Empresa"
                                        disabled={this.state.disabled}
                                        onClick={() => this.setState({type: "company"}, () => {window.scrollTo(0, 0)})}
                                        style={{float: "none", backgroundColor: "#fa5b73", marginBottom: "0px"}}
                                        /><br />
                                        <span style={{fontSize: "9px", lineHeight: "12px", maxWidth: "155px", display: "block", float: "right", marginTop: "10px"}}>(PARA BRINDES - EVENTOS - <br />SUBSTITUIÇÃO  DE DESCARTÁVEIS...)</span>
                                    </Col>
                                    <Col xs={6} md={6} style={{textAlign: "left"}}>
                                        <input
                                        className="btn-submit"
                                        type="button"
                                        value="Revenda"
                                        disabled={this.state.disabled}
                                        onClick={() => this.setState({type: "resale_new"}, () => {window.scrollTo(0, 0);})}
                                        style={{float: "none", backgroundColor: "#fa5b73", marginBottom: "0px"}}
                                        />
                                    </Col>
                                    </Row>
                                    <Row>
                                    <Col xs={12} md={12} style={{textAlign: "center"}}>
                                        <br /><br />
                                        <Link
                                        className="btn-submit"
                                        to={`${process.env.PUBLIC_URL}/welcome`}
                                        style={{paddingBottom: "20px"}}
                                        >Voltar</Link>
                                    </Col>
                                    </Row>
                                </Col>
                            </Row>*/}
                        {/*) : (*/}
                        <form encType="multipart/form-data">
                            {this.state.productsA.length > 0 && this.state.productsT.length > 0 && this.state.productsN.length > 0 ? (
                                <Row id='row-step' className='row-step'>
                                    <Col xs={12} md={12}>
                                        <h2>Solicite o seu orçamento</h2>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <p className="text-introduction">Por favor, preencha os campos abaixo para solicitar um orçamento.
                                        </p>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <h3>Dados da Empresa</h3> {/* ############################  Dados Pessoais */}
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <label className="label-register"><span>Nome da Empresa</span>
                                            <input
                                                name="name"
                                                type="text"
                                                ref={this.name}
                                                required={false}
                                                title="Preencha o campo Nome"
                                                value={this.state.name || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <label className="label-register"><span>E-mail</span><sup className="required-field">*</sup>
                                            <input
                                                name="email"
                                                type="email"
                                                ref={this.email}
                                                required={true}
                                                value={this.state.email || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <label className="label-register"><span>Responsável</span><sup className="required-field">*</sup>
                                            <InputMask
                                                name="person"
                                                type="text"
                                                ref={this.person}
                                                required={true}
                                                value={this.state.person || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <label className="label-register"><span>Telefone</span><sup className="required-field">*</sup>
                                            <InputMask
                                                name="phone"
                                                type="text"
                                                ref={this.phone}
                                                mask="(99) 99999-9999"
                                                required={true}
                                                value={this.state.phone || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <label className="label-register"><span>Cargo</span>
                                            <InputMask
                                                name="cargo"
                                                type="text"
                                                required={false}
                                                ref={this.cargo}
                                                value={this.state.cargo || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col>

                                    {/* <Col xs={12} md={6}>
                                        <label className="label-register"><span>CNPJ/CPF</span><sup className="required-field">*</sup>
                                            <CpfCnpj
                                                name="cnpj"
                                                type="text"
                                                ref={this.cnpj}
                                                required={true}
                                                value={this.state.cnpj || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col> */}
                                    {/* <Col xs={12} md={6}>
                                        <label className="label-register"><span>Inscrição Estadual <small>(somente números)</small></span><sup className="required-field">*</sup>
                                            <InputMask
                                                name="state_subscription"
                                                type="number"
                                                ref={this.state_subscription}
                                                required={true}
                                                value={this.state.state_subscription || ''}
                                                onChange={this.handleChange}
                                            />
                                            <div className="no-ss">
                                                <input name="no_ss" type="checkbox" ref={this.no_ss} value={this.state.no_ss || false}
                                                    onChange={this.handleChecked} checked={this.state.no_ss || false} /> <span>ISENTO</span>
                                            </div>
                                        </label>
                                    </Col> */}
                                    {/* <Col xs={12} md={12}>
                                        <h3>Endereço de Faturamento</h3>
                                    </Col> */}
                                    <Col xs={12} md={6}>
                                        <label className="label-register"><span>CEP</span>
                                            <InputMask
                                                name="address_zipcode"
                                                type="text"
                                                mask="99999-999"
                                                ref={this.address_zipcode}
                                                required={false}
                                                value={this.state.address_zipcode || ''}
                                                onChange={this.handleChangeCEP}
                                            />
                                        </label>
                                    </Col>
                                    {/* <Col xs={12} md={6}>
                                        <label className="label-register"><span>Rua</span><sup className="required-field">*</sup>
                                            <input
                                                name="address_street"
                                                type="text"
                                                ref={this.address_street}
                                                required={true}
                                                value={this.state.address_street || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col> */}
                                    {/* <Col xs={12} md={6}>
                                        <label className="label-register"><span>Número</span><sup className="required-field">*</sup>
                                            <input
                                                name="address_number"
                                                type="text"
                                                ref={this.address_number}
                                                required={true}
                                                value={this.state.address_number || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col> */}
                                    {/* <Col xs={12} md={6}>
                                        <label className="label-register"><span>Complemento</span>
                                            <input
                                                name="address_complement"
                                                type="text"
                                                ref={this.address_complement}
                                                value={this.state.address_complement || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col> */}
                                    {/* <Col xs={12} md={6}>
                                        <label className="label-register"><span>Bairro</span><sup className="required-field">*</sup>
                                            <input
                                                name="address_neighborhood"
                                                type="text"
                                                ref={this.address_neighborhood}
                                                required={true}
                                                value={this.state.address_neighborhood || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col> */}
                                    {/* <Col xs={12} md={6}>
                                        <label className="label-register"><span>Estado</span><sup className="required-field">*</sup>
                                            <select
                                                name="address_state"
                                                type="text"
                                                ref={this.address_state}
                                                required={true}
                                                value={this.state.address_state || ''}
                                                onChange={this.handleChange}
                                            >
                                                <option value="">Escolha um estado</option>
                                                <option value="AC">Acre</option>
                                                <option value="AL">Alagoas</option>
                                                <option value="AP">Amapá</option>
                                                <option value="AM">Amazonas</option>
                                                <option value="BA">Bahia</option>
                                                <option value="CE">Ceará</option>
                                                <option value="DF">Distrito Federal</option>
                                                <option value="ES">Espírito Santo</option>
                                                <option value="GO">Goiás</option>
                                                <option value="MA">Maranhão</option>
                                                <option value="MT">Mato Grosso</option>
                                                <option value="MS">Mato Grosso do Sul</option>
                                                <option value="MG">Minas Gerais</option>
                                                <option value="PA">Pará</option>
                                                <option value="PB">Paraíba</option>
                                                <option value="PR">Paraná</option>
                                                <option value="PE">Pernambuco</option>
                                                <option value="PI">Piauí</option>
                                                <option value="RJ">Rio de Janeiro</option>
                                                <option value="RN">Rio Grande do Norte</option>
                                                <option value="RS">Rio Grande do Sul</option>
                                                <option value="RO">Rondônia</option>
                                                <option value="RR">Roraima</option>
                                                <option value="SC">Santa Catarina</option>
                                                <option value="SP">São Paulo</option>
                                                <option value="SE">Sergipe</option>
                                                <option value="TO">Tocantins</option>
                                                <option value="ES">Estrangeiro</option>
                                            </select>
                                        </label>
                                    </Col> */}
                                    {/* <Col xs={12} md={6}>
                                        <label className="label-register"><span>Cidade</span><sup className="required-field">*</sup>
                                            <input
                                                name="address_city"
                                                type="text"
                                                ref={this.address_city}
                                                required={true}
                                                value={this.state.address_city || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col> */}
                                    {/* <Col xs={12} md={12}>
                                        <h3>Contato</h3>
                                    </Col> */}
                                    {/* <Col xs={12} md={6}>
                                        <label className="label-register"><span>Responsável</span><sup className="required-field">*</sup>
                                            <InputMask
                                                name="person"
                                                type="text"
                                                ref={this.person}
                                                required={true}
                                                value={this.state.person || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col> */}
                                    {/* <Col xs={12} md={6}>
                                        <label className="label-register"><span>Telefone</span><sup className="required-field">*</sup>
                                            <InputMask
                                                name="phone"
                                                type="text"
                                                ref={this.phone}
                                                mask="(99) 99999-9999"
                                                required={true}
                                                value={this.state.phone || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col> */}
                                    {/* <Col xs={12} md={6}>
                                        <label className="label-register"><span>Área</span><sup className="required-field">*</sup>
                                            <InputMask
                                                name="area"
                                                type="text"
                                                required={true}
                                                ref={this.area}
                                                value={this.state.area || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col> */}
                                    {/* <Col xs={12} md={6}>
                                        <label className="label-register"><span>Cargo</span>
                                            <InputMask
                                                name="cargo"
                                                type="text"
                                                required={false}
                                                ref={this.cargo}
                                                value={this.state.cargo || ''}
                                                onChange={this.handleChange}
                                            />
                                        </label>
                                    </Col> */}
                                    <Col xs={12} md={12}>
                                        <h2><strong>Selecione os produtos</strong></h2>
                                    </Col>
                                    <Col xs={12} md={12} style={{ marginTop: "30px" }}>
                                        <p style={{ paddingBottom: "30px", textAlign: "center" }}>O nosso pedido mínimo é de 40 unidades, ok??<br /><small>Caso deseje menos de 40 unidades, finalize seu pedido com a quantidade mínima (40 unid.) e após receber seu orçamento converse com nossa equipe comercial.</small></p>
                                        <h2 style={{textAlign: "center", marginBottom: "20px"}}><strong>Linha Personalizados</strong></h2>
                                        <Row>
                                            {this.state.productsN.map((products, i) => {
                                                return <ProductItem key={i} item={products} id={i} setQuantity={this.setQuantity} />;
                                            })}
                                        </Row>

                                        <h2 style={{textAlign: "center", marginBottom: "20px"}}><strong>Linha Quartzo</strong></h2>
                                        <Row>
                                            {this.state.productsT.map((products, i) => {
                                                return <ProductItem key={i} item={products} id={i} setQuantity={this.setQuantity} />;
                                            })}
                                        </Row>

                                        <h2 style={{textAlign: "center", marginBottom: "20px"}}><strong>Linha Flores & Frutos</strong></h2>
                                        <Row>
                                            {this.state.productsA.map((products, i) => {
                                                return <ProductItem key={i} item={products} id={i} setQuantity={this.setQuantity} />;
                                            })}
                                        </Row>
                                    </Col>

                                    {/* <Col xs={12} md={12}>
                                        <label className="label-register"><span>Você já é cliente Menos 1 Lixo?</span><sup className="required-field">*</sup>
                                            <select
                                                name="newClient"
                                                ref={this.newClient}
                                                value={this.state.newClient || ''}
                                                onChange={this.handleChange}
                                            >
                                                <option value="">Selecione</option>
                                                <option value="0">Sim e estou de Volta!</option>
                                                <option value="1">Não, sou novo por aqui!</option>
                                            </select>
                                        </label>
                                    </Col> */}
                                    <Col xs={12} md={12}>
                                        <label className="label-register"><span>Quero Comprar o Copinho Menos 1 Lixo</span><sup className="required-field">*</sup>
                                            <select
                                                name="type"
                                                ref={this.type}
                                                value={this.state.type || ''}
                                                onChange={this.handleChange}
                                            >
                                                <option value="">Selecione</option>
                                                <option value="company">Para minha Empresa</option>
                                                <option value="resale_new">Para Revender</option>
                                            </select>
                                        </label>
                                    </Col>
                                    {/* <Col xs={12} md={12}>
                                        <label className="label-register"><span>Os Copinhos Menos 1 Lixo serão utilizados para</span><sup className="required-field">*</sup>
                                            <select
                                                name="used_to"
                                                ref={this.used_to}
                                                value={this.state.used_to || ''}
                                                onChange={this.handleChangeUsedTo}
                                            >
                                                <option value="">Selecione</option>
                                                <option value="Brinde">Brinde</option>
                                                <option value="Parte de estratégias para Sustentabilidade na Empresa">Parte de estratégias para Sustentabilidade na Empresa</option>
                                                <option value="Redução de despesas com descartáveis">Redução de despesas com descartáveis</option>
                                                <option value="Produto de Revenda">Produto de Revenda</option>
                                                <option value="Item de kit para Marketing e Divulgação">Item de kit para Marketing e Divulgação</option>
                                                <option value="Outros">Outros</option>
                                            </select>
                                        </label>
                                    </Col> */}
                                    <Col xs={12} md={12}>
                                        <label className="label-register"><span>É personalizado?</span><br />
                                            <small style={{ color: "#fa5872" }}>A personalização acontece na superfície externa da tampa</small>
                                            <select
                                                name="printing"
                                                ref={this.printing}
                                                value={this.state.printing || ''}
                                                onChange={this.handleChangePrinting}
                                            >
                                                <option value={0}>Não</option>
                                                <option value={1}>Sim</option>
                                            </select>
                                        </label>
                                    </Col>
                                    {this.state.printing === "1" && (
                                        <Col xs={12} md={12}>
                                            <label className="label-register"><span>Quantas cores tem o logo?</span>
                                                <select
                                                    name="logo_colors"
                                                    ref={this.logo_colors}
                                                    value={this.state.logo_colors || ''}
                                                    onChange={this.handleChange}
                                                >
                                                    <option value={0}>Selecione a resposta</option>
                                                    <option value={1}>1</option>
                                                    <option value={2}>2</option>
                                                    <option value={3}>3</option>
                                                    <option value={4}>4</option>
                                                </select>
                                            </label>

                                            <p><strong>Está com dúvida como escolher a opção correta para sua logo? A gente te ajuda!</strong></p>
                                            <ul>
                                                <li>- Importante: não trabalhamos com  degradê, as cores da logo devem ser “chapadas” - que é quando a cor tem tonalidade única, sem qualquer variação. </li>
                                                <li>- Se a sua logo tiver mais de 4 cores (ser colorida), optar por 4 cores.</li>
                                            </ul>
                                        </Col>
                                    )}
                                    <Col xs={12} md={12}>
                                        <label>
                                            <input
                                                name="aceite"
                                                type="checkbox"
                                                ref={this.aceite}
                                                value={this.state.aceite || false}
                                                onChange={this.handleChecked}
                                                style={{ width: "30px" }}
                                            />
                                            <span>Li e concordo com os termos da <a href="https://loja.menos1lixo.com.br/pol%C3%ADtica-privacidade" target="_blank" rel="noopener noreferrer">Política de Privacidade</a></span>
                                        </label>
                                        <br/>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        {/*<Link to="welcome" className="btn-submit btn-logout">Voltar</Link> */}
                                        <input
                                            className="btn-submit"
                                            type="button"
                                            value={this.state.textBtn}
                                            disabled={this.state.disabled}
                                            onClick={() => this.sendOrder()}
                                        />
                                    </Col>
                                    <Col xs={12} md={12} className="row-btn-submit">

                                        {this.state.errors !== "" && this.state.showError && (<div id="invalid-feedback" className="invalid-feedback">{this.state.errors}</div>)}

                                    </Col>

                                </Row>
                            ) : (
                                <p style={{ textAlign: "center" }}>Carregando...</p>
                            )}
                        </form>
                        {/*						)}*/}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

class ProductItem extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            id: this.props.item.id,
            quantity: 0
        }

    }

    async handleChange(e) {
        if (e.target.value === "") {
            e.target.value = 0
            await this.setState({
                [e.target.name]: e.target.value
            });
            this.props.setQuantity(this.state);
        } else {
            e.target.value = e.target.value.replace(/^0+/, '')
            if (e.target.value === "") {
                e.target.value = 0
                await this.setState({
                    [e.target.name]: e.target.value
                });
                this.props.setQuantity(this.state);
            } else {
                await this.setState({
                    [e.target.name]: e.target.value
                });
                this.props.setQuantity(this.state);
            }
        }
    }

    render() {
        return (
            <Col xs={12} md={6}>
                <Row>
                    <Col xs={6} md={6} style={{ textAlign: "center" }}>
                        <img src={process.env.REACT_APP_URL + 'img/' + this.props.item.name + '.jpg'} alt='' style={{ maxWidth: "120px" }} />
                    </Col>
                    <Col xs={6} md={6}>
                        <label className="label-register"><span>{this.props.item.name}</span>
                            <p>Qtde: <input
                                name="quantity"
                                type="number"
                                min={0}
                                required={true}
                                value={this.state.quantity}
                                onChange={this.handleChange}
                            /></p>
                        </label>
                    </Col>
                </Row>
            </Col>
        )
    }
}

export default OrderPage