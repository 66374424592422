import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import { PostApi } from '../services/ApiServices';
import { Link } from 'react-router-dom';

class RememberAccessPagePage extends Component {
  constructor() {
    super();

    this.state = {
      login: '',
      validLogin: false,
      showError: false,
      isDisabled: '',
      btnText: 'Enviar'
    };

    this.rememberPassword = this.rememberPassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
		window.scrollTo(0, 0);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
	}

  async rememberPassword(e) {
    console.log(this.state.email)
    e.preventDefault();
    if (this.state.email) {
      this.setState({ isDisabled: "disabled", btnText: "Aguarde..." });
      const { email } = this.state
      
      const rememberLogin = await PostApi('password/email', { email })
      
      if (rememberLogin.data && rememberLogin.data.token) {
        this.setState({ validLogin: true });
      } else {
        if(rememberLogin.message === 'passwords.user') {
          this.setState({ errors: "Esse e-mail não foi encontrado em nossa base de dados", showError: true, isDisabled: "", btnText: "Enviar" })
          setTimeout(() => {
            this.toggleHidden()
          }, 5000)
        } else {
          this.setState({ errors: "Erro no Servidor", showError: true, isDisabled: "", btnText: "Enviar" })
          setTimeout(() => {
            this.toggleHidden()
          }, 5000)
        }
      }
    }
  }

  toggleHidden () {
    this.setState({
      showError: !this.state.showError
    })
  }

  render() {

    return (
      <Container>
        <Row style={{display: "initial"}}>
          <form>
            <Col xs={12} offset={{ md: 3 }} md={6}>
              <div className="area-box area-login">
                {this.state.validLogin === false &&
                  <React.Fragment>
                    <Col xs={12} md={12}>
                      <p>Digite seu e-mail no campo abaixo para receber instruções de como alterar sua senha. Depois verifique sua caixa de entrada, spam ou outros. Pode ser que o email demore algum tempo para chegar.</p>
                    </Col>
                    <Col xs={12} md={12}>
                      <label className="label-register"><IoMdHelpCircleOutline /><span>E-mail</span><sup className="required-field">*</sup>
                      <input
                          name="email"
                          type="email"
                          ref={this.email}
                          required={true}
                          value={this.state.email || ''}
                          onChange={this.handleChange}
                      />
                      </label>
                    </Col>
                    <Col xs={12} md={12} className="row-btn-submit">
                      <input type="button" value={this.state.btnText} className="btn-submit"
                        onClick={this.rememberPassword}
                        disabled={this.state.isDisabled}
                         />
                    </Col>
                    <Col xs={12} md={12} className="row-remember-password">
                      <Link className="remember-password" to="/login">Voltar para o login</Link>
                    </Col>
                  </React.Fragment>
                }{this.state.validLogin === true &&
                  <React.Fragment>
                    <Col xs={12} md={12}>
                      <p>Foi enviado um e-mail com as instruções para cadastrar uma nova senha.</p>
                    </Col>
                    <Col xs={12} md={12} className="row-remember-password">
                      <Link className="remember-password" to="/login">Voltar para o login</Link>
                    </Col>
                  </React.Fragment>
                }
              </div>
            </Col>
          </form>
        </Row>
        <Col xs={12} md={12} className="row-btn-submit">

            {this.state.errors !== "" && this.state.showError && (<div id="invalid-feedback" className="invalid-feedback" onClick={this.toggleHidden.bind(this)}>{this.state.errors}<span>x</span></div>)}
            
          </Col>
      </Container>
    );
  }
}

export default RememberAccessPagePage;
