import { Row, Col } from 'react-grid-system';
import { GetApi, PostApi } from '../services/ApiServices';
import { Redirect, Link } from 'react-router-dom';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import * as cep from 'cep-promise'

class RegisterOrderPage extends Component {

  constructor() {
    super();

    this.state = {
			name: '',
      cnpj: '',
      state_subscription: '',
      no_ss: false,
			email: '',
			type: '',
      sales_channel: '',
      sales_notes: '',
			address_zipcode: '',
			address_street: '',
			address_number: '',
			address_complement: '',
			address_neighborhood: '',
			address_state: '',
			address_city: '',
			person: '',
			phone: '',
			area: '',
			cargo: '',
			errors: '',
      textBtn: 'Salvar',
      disabled: false,
      showError: false
    };

    this.register = this.register.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.handleChangeCEP = this.handleChangeCEP.bind(this);
		this.back = this.back.bind(this);
		window.scrollTo(0, 0);
  }

  async componentDidMount() {
    this.mounted = true;

		if (this.mounted) {
      var order = await PostApi('orders/get', {id: this.props.match.params.orderID, token: this.props.match.params.token})

      if (order[0].type === "resale_new") {
        order[0].type = "resale"
      }
      
      if (order.length > 0) {
        this.setState({
          name: order[0].name,
          email: order[0].email,
          type: order[0].type,
          person: order[0].person,
          phone: order[0].phone,
          area: order[0].area,
          cargo: order[0].cargo,
          empty: 2
        })
      } else {
        this.setState({
          empty: 1
        })
      }
		}
	}

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  
  handleChecked(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  async handleChangeCEP(e) {

    this.setState({
			address_zipcode: e.target.value
    });

    if (this.IsCEP(e.target.value)) {
			var cepNumber = e.target.value.replace("-","");
			cepNumber = cepNumber.replace(/\./,"");
			var data = await cep(cepNumber);
			
			this.setState({
				address_street: data.street,
				address_neighborhood: data.neighborhood,
				address_city: data.city,
				address_state: data.state
			});
		}
  }

	IsCEP(strCEP) {
    // Caso o CEP não esteja nesse formato ele é inválido!
    var objER = /^[0-9]{2}[0-9]{3}-[0-9]{3}$/;

    if(strCEP.length > 0) {
      if(objER.test(strCEP))
        return true;
      else
        return false;
    } else
    	return false;
  }
  
  validarCNPJ(n) {
    var cnpj = n.replace(/[^\d]+/g,'');
    
    if(cnpj === '') return false;
    
    if (cnpj.length !== 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" || 
        cnpj === "11111111111111" || 
        cnpj === "22222222222222" || 
        cnpj === "33333333333333" || 
        cnpj === "44444444444444" || 
        cnpj === "55555555555555" || 
        cnpj === "66666666666666" || 
        cnpj === "77777777777777" || 
        cnpj === "88888888888888" || 
        cnpj === "99999999999999")
        return false;
          
      // Valida DVs
      var tamanho = cnpj.length - 2
      var numeros = cnpj.substring(0,tamanho);
      var digitos = cnpj.substring(tamanho);
      var soma = 0;
      var pos = tamanho - 7;
      for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

      if (resultado !== parseInt(digitos.charAt(0)))
          return false;
          
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0,tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
              pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado !== parseInt(digitos.charAt(1)))
            return false;
            
      return true;
      
  }

	back() {
		window.scrollTo(0, 0);
		this.setState({type: ''})
	}

	async register() {
		const { name, cnpj, no_ss, state_subscription, email, password, password_confirmation, type, sales_channel, sales_notes, address_zipcode, address_street, address_number, address_complement, address_neighborhood, address_state, address_city, person, phone, area, cargo } = this.state

		this.setState({
			disabled: true,
			textBtn: 'Aguarde'
    })
    
    var ie = require("inscricaoestadual");

    if (address_state === "") {
      this.toTop();
        this.setState({ errors: "Preencha os campos de endereço primeiro", showError: true, disabled: false, textBtn: 'Entrar' })
        setTimeout(() => {
          this.toggleHidden()
        }, 5000)
      return false;
    }

    if (!no_ss) {
      if (!ie(state_subscription, address_state)) {
        this.toTop();
          this.setState({ errors: "Inscrição Estadual inválida!", showError: true, disabled: false, textBtn: 'Entrar' })
          setTimeout(() => {
            this.toggleHidden()
          }, 5000)
        return false;
      }
    }
    
    if (!this.validarCNPJ(cnpj)) {
      this.toTop();
        this.setState({ errors: "CNPJ inválido!", showError: true, disabled: false, textBtn: 'Entrar' })
        setTimeout(() => {
          this.toggleHidden()
        }, 5000)
      return false;
    }

    if (phone.length !== 15) {
      this.toTop();
        this.setState({ errors: "Telefone inválido", showError: true, disabled: false, textBtn: 'Entrar' })
        setTimeout(() => {
          this.toggleHidden()
        }, 5000)
      return false;
    }

		const data = await PostApi('signup', { name, cnpj, no_ss, state_subscription, email, password, password_confirmation, type, sales_channel, sales_notes, address_zipcode, address_street, address_number, address_complement, address_neighborhood, address_state, address_city, person, phone, area, cargo  })
		
		if (data.code === 201) {
			const data = await PostApi('login', { email, password  })
			if (data.access_token) {
        const user =  await GetApi('user', data.access_token)
        if (user) {
          sessionStorage.setItem('menosumlixo_access_token', (data.access_token));
          sessionStorage.setItem('menosumlixo_client', JSON.stringify(user));
          window.location.href = '/order/'+this.props.match.params.orderID+'/'+this.props.match.params.token;
        } else {
					this.toTop();
          this.setState({ errors: "Ocorreu um erro! Entre em contato com o administrador.", showError: true, disabled: false, textBtn: 'Entrar' })
          setTimeout(() => {
            this.toggleHidden()
          }, 5000)
        }  
      } else {
				this.toTop();
        this.setState({ errors: "Ocorreu um erro! Entre em contato com o administrador.", showError: true, disabled: false, textBtn: 'Entrar' })
        setTimeout(() => {
          this.toggleHidden()
        }, 5000)
      }
		} else {
			if (data.errors) {
				var keys = Object.keys(data.errors)
				var field = document.getElementsByName(keys[0])
				var message = data.errors[Object.keys(data.errors)[0]]
				field[0].focus();
			}

			this.setState({ errors: message[0], showError: true, disabled: false, textBtn: 'Salvar' })
			setTimeout(() => {
				this.toggleHidden()
			}, 5000)
		}
  }

  toggleHidden () {
    this.setState({
      showError: !this.state.showError
    })
	}
	
	toTop() {
    window.scrollTo(0, 80);
  }

  render() {
    console.log(this.state)
    if (sessionStorage.menosumlixo_access_token) {
      return (
        <Redirect to={'/'} />
      )
    } else {
      return (
        <React.Fragment>
          <Row className="row-order-area">
              <Col xs={12} sm={10} md={10} lg={10} className="area-box form-order">
                {this.state.empty ? (this.state.empty === 1 ? (
                  <Row>
                    <Col xs={12} md={12}>
                      <p className="text-introduction" style={{textAlign: "center"}}>Pedido inválido ou expirado
                      </p>
                    </Col>
                    <Col xs={12} md={12}>
                      <Link
                      className="btn-submit"
                      to="/">Voltar</Link>
                    </Col>
                  </Row>
                ) : (
                  <form encType="multipart/form-data">
                    <Row id='row-step' className='row-step'>
                    <Col xs={12} md={12}>
                        <h2>Registre-se</h2>
                    </Col>
                    <Col xs={12} md={12}>
                        <p className="text-introduction">Para faturar seu pedido você precisa se cadastrar em nosso sistema.
                        </p>
                    </Col>
                    <Col xs={12} md={12}>
                        <h3>Dados da Empresa</h3> {/* ############################  Dados Pessoais */}
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Nome da Empresa</span><sup className="required-field">*</sup>
                        <input
                            name="name"
                            type="text"
                            ref={this.name}
                            required={true}
                            title="Preencha o campo Nome"
                            value={this.state.name || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>CNPJ</span><sup className="required-field">*</sup>
                        <InputMask
                            name="cnpj"
                            type="text"
                            ref={this.cnpj}
                            required={true}
                            mask="99.999.999/9999-99"
                            value={this.state.cnpj || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Inscrição Estadual <small>(somente números)</small></span><sup className="required-field">*</sup> 
                        <InputMask
                            name="state_subscription"
                            type="number"
                            ref={this.state_subscription}
                            required={true}
                            value={this.state.state_subscription || ''}
                            onChange={this.handleChange}
                        />
                        <div className="no-ss"><input name="no_ss" type="checkbox" value="1"
                            onChange={this.handleChecked} /> <span>ISENTO</span></div>
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>E-mail</span><sup className="required-field">*</sup>
                        <input
                            name="email"
                            type="email"
                            ref={this.email}
                            required={true}
                            value={this.state.email || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Senha</span><sup className="required-field">*</sup>
                        <input
                            name="password"
                            type="password"
                            ref={this.password}
                            required={true}
                            value={this.state.password || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Confirmar Senha</span><sup className="required-field">*</sup>
                        <input
                            name="password_confirmation"
                            type="password"
                            ref={this.password_confirmation}
                            required={true}
                            value={this.state.password_confirmation || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                      
                    <Col xs={12} md={12}>
                        <h3>Canal de Vendas</h3>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register">
                        <select 
                          name="sales_channel"
                          type="text"
                          ref={this.sales_channel}
                          required={true}
                          value={this.state.sales_channel || ''}
                          onChange={this.handleChange}
                          >
                            <option value="">Selecione</option>
                            <option value="Loja física">Loja física</option>
                            <option value="Loja online">Loja online</option>
                            <option value="Loja física/online">Loja física/online</option>
                            <option value="Outros">Outros</option>
                        </select>
                        </label>
                    </Col>
                    {this.state.sales_channel === "Loja física" && (
                      <Col xs={12} md={12}>
                        <label className="label-register"><span>Escreva o(s) endereço(s) da sua loja</span><sup className="required-field">*</sup>
                        <textarea 
                          name="sales_notes"
                          ref={this.sales_notes}
                          required={true}
                          value={this.state.sales_notes || ''}
                          onChange={this.handleChange}
                          >{this.state.sales_notes}</textarea>
                        </label>
                      </Col>
                    )}
                    {this.state.sales_channel === "Loja online" && (
                      <Col xs={12} md={12}>
                        <label className="label-register"><span>Escreva o(s) endereço(s) da sua loja na Internet</span><sup className="required-field">*</sup>
                        <textarea 
                          name="sales_notes"
                          ref={this.sales_notes}
                          required={true}
                          value={this.state.sales_notes || ''}
                          onChange={this.handleChange}
                          >{this.state.sales_notes}</textarea>
                        </label>
                      </Col>
                    )}
                    {this.state.sales_channel === "Loja física/online" && (
                      <Col xs={12} md={12}>
                        <label className="label-register"><span>Escreva o(s) endereço(s) da sua loja e do(s) site(s)</span><sup className="required-field">*</sup>
                        <textarea 
                          name="sales_notes"
                          ref={this.sales_notes}
                          required={true}
                          value={this.state.sales_notes || ''}
                          onChange={this.handleChange}
                          >{this.state.sales_notes}</textarea>
                        </label>
                      </Col>
                    )}
                    {this.state.sales_channel === "Outros" && (
                      <Col xs={12} md={12}>
                        <label className="label-register"><span>Explica um pouco mais pra gente</span><sup className="required-field">*</sup>
                        <textarea 
                          name="sales_notes"
                          ref={this.sales_notes}
                          required={true}
                          value={this.state.sales_notes || ''}
                          onChange={this.handleChange}
                          >{this.state.sales_notes}</textarea>
                        </label>
                      </Col>
                    )}
                    <Col xs={12} md={12}>
                        <h3>Endereço de Faturamento</h3>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>CEP</span><sup className="required-field">*</sup>
                        <InputMask
                            name="address_zipcode"
                            type="text"
                            mask="99999-999"
                            ref={this.address_zipcode}
                            required={true}
                            value={this.state.address_zipcode || ''}
                            onChange={this.handleChangeCEP}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Rua</span><sup className="required-field">*</sup>
                        <input
                            name="address_street"
                            type="text"
                            ref={this.address_street}
                            required={true}
                            value={this.state.address_street || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Número</span><sup className="required-field">*</sup>
                        <input
                            name="address_number"
                            type="text"
                            ref={this.address_number}
                            required={true}
                            value={this.state.address_number || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Complemento</span>
                        <input
                            name="address_complement"
                            type="text"
                            ref={this.address_complement}
                            value={this.state.address_complement || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Bairro</span><sup className="required-field">*</sup>
                        <input
                            name="address_neighborhood"
                            type="text"
                            ref={this.address_neighborhood}
                            required={true}
                            value={this.state.address_neighborhood || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Estado</span><sup className="required-field">*</sup>
                        <select 
                        name="address_state"
                        type="text"
                        ref={this.address_state}
                        required={true}
                        value={this.state.address_state || ''}
                        onChange={this.handleChange}
                        >
                          <option value="">Escolha um estado</option>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                          <option value="ES">Estrangeiro</option>
                        </select>
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Cidade</span><sup className="required-field">*</sup>
                        <input
                            name="address_city"
                            type="text"
                            ref={this.address_city}
                            required={true}
                            value={this.state.address_city || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={12}>
                        <h3>Contato</h3>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Responsável</span><sup className="required-field">*</sup>
                        <InputMask
                            name="person"
                            type="text"
                            ref={this.person}
                            required={true}
                            value={this.state.person || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Telefone</span><sup className="required-field">*</sup>
                        <InputMask
                            name="phone"
                            type="text"
                            ref={this.phone}
                            mask="(99) 99999-9999"
                            required={true}
                            value={this.state.phone || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Área</span><sup className="required-field">*</sup>
                        <InputMask
                            name="area"
                            type="text"
                            ref={this.area}
                            required={true}
                            value={this.state.area || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={6}>
                        <label className="label-register"><span>Cargo</span><sup className="required-field">*</sup>
                        <InputMask
                            name="cargo"
                            type="text"
                            ref={this.cargo}
                            required={true}
                            value={this.state.cargo || ''}
                            onChange={this.handleChange}
                        />
                        </label>
                    </Col>
                    <Col xs={12} md={12}>
                        <input
                        className="btn-submit"
                        type="button"
                        value="Voltar"
                        onClick={() => this.back()}
                        />
                        <input
                        className="btn-submit"
                        type="button"
                        value={this.state.textBtn}
                        disabled={this.state.disabled}
                        onClick={() => this.register()}
                        />
                    </Col>
                    <Col xs={12} md={12} className="row-btn-submit">

                        {this.state.errors !== "" && this.state.showError && (<div id="invalid-feedback" className="invalid-feedback">{this.state.errors}</div>)}
                        
                    </Col>
                    </Row>
                  </form>
                )) : (
                  <Col xs={12} md={12}>
                    <p className="text-introduction" style={{textAlign: "center"}}>Carregando...
                    </p>
                  </Col>
                )}
              </Col>
          </Row>
      </React.Fragment>
      );
    }
  }
}

export default RegisterOrderPage;