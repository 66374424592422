import { DeleteApi } from "../services/ApiServices";
import { Link } from "react-router-dom";
import { Row, Col } from "react-grid-system";
import React, { Component } from "react";

class OrderPage extends Component {
  constructor(props) {
    super(props);

    if (!this.props.history.location.order) {
      this.props.history.push({
        pathname: "/",
      });
    }

    if (
      sessionStorage.menosumlixo_client &&
      sessionStorage.menosumlixo_access_token
    ) {
      this.SessionClient = JSON.parse(sessionStorage.menosumlixo_client);
      this.SessionAccessToken = sessionStorage.menosumlixo_access_token;
    }

    this.state = {};
    window.scrollTo(0, 0);
  }

  async sign_out() {
    await DeleteApi("logout", sessionStorage.menosumlixo_access_token);
    window.location.href = `${process.env.PUBLIC_URL}/`;
  }

  render() {
    return (
      <React.Fragment>
        <Row className="row-order-area">
          <Col xs={12} sm={10} md={10} lg={10} className="area-box form-order">
            <form encType="multipart/form-data">
              <Row id="row-step" className="row-step">
                <Col xs={12} md={12} style={{ textAlign: "center" }}>
                  <h2>Pedido Realizado com Sucesso</h2>
                </Col>
                <Col xs={12} md={12} style={{ marginTop: "30px" }}>
                  <p style={{ textAlign: "center" }}>
                    Por aqui a nossa missão é estimular o empoderamento de
                    indivíduos e organizações para alcançarmos uma forma mais
                    sustentável de viver em sociedade! Vai ser maravilhoso ter
                    vocês com a gente nesta missão!
                  </p>
                  <p style={{ paddingBottom: "30px", textAlign: "center" }}>
                    Estamos preparando seu orçamento com muito carinho e em
                    algumas horas ele chegará pra você por e-mail, tá? Prometo
                    que não demora. ;) Hahah
                  </p>
                  <p style={{ paddingBottom: "30px", textAlign: "center" }}>
                    Caso tenha urgência, pode escrever para
                    comercial@menos1lixo.com.br
                  </p>
                  <p style={{ paddingBottom: "30px", textAlign: "center" }}>
                    Obrigada pelo contato, já já nos falamos!
                  </p>
                </Col>
                <Col xs={12} md={12}>
                  {sessionStorage.menosumlixo_access_token ? (
                    <Link
                      to="#"
                      onClick={this.sign_out}
                      className="btn-submit btn-logout"
                    >
                      Sair
                    </Link>
                  ) : (
                    <Link to="welcome" className="btn-submit btn-logout">
                      Voltar
                    </Link>
                  )}
                  {/* <Link to="/" className="btn-submit">
                    Fazer outro pedido
                  </Link> */}
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default OrderPage;
