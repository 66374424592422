import axios from 'axios';

async function DeleteApi(type,accessToken) {

  const ApiUrl = process.env.REACT_APP_API_URL + type;
  var headers

  if (accessToken) {
    headers = {
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + accessToken
    }
  } else {
    headers = {
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
  }
  
  const { data } = await axios.delete(ApiUrl, {
      headers: headers
    }).catch(function (error) {
      console.log(error)
      if (error.response) {
        return error.response
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    })
    sessionStorage.clear();
    
    return data
}
export default DeleteApi;
