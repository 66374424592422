import React, { Component } from "react";

class Loader extends Component {
  render() {
    return (
        <div>
            Loading...
        </div>
    );
  }
}

export default Loader;
