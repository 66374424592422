import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { IoMdHelpCircleOutline } from 'react-icons/io';
import { PutApi } from '../services/ApiServices';
import queryString from 'query-string'
import { Link } from 'react-router-dom';

class changePasswordPage extends Component {
  constructor(props) {
    super(props);

    const query = queryString.parse(this.props.location.search)
    const token = this.props.match.url.split("/")
    
    this.state = {
      token: token[3],
      email: query.email,
      password: "",
      password_confirmation: "",
      errors: "",
      showError: false,
      validLogin: false
    };

    this.changePassword = this.changePassword.bind(this);
    this.onChange = this.onChange.bind(this);
		window.scrollTo(0, 0);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async changePassword(e) {
    e.preventDefault();
    if (this.state.password === this.state.password_confirmation) {
      this.setState({ isDisabled: "disabled", btnText: "Aguarde..." });
      const { email } = this.state
      const { password } = this.state
      const { password_confirmation } = this.state
      const { token } = this.state
      
      const changePassword = await PutApi('password/reset', { email, password, password_confirmation, token }, false)
      
      if (changePassword.message === 'passwords.reset') {
        this.setState({ validLogin: true });
      } else if (changePassword.message === 'passwords.token') {
        this.setState({ errors: "Token inválido", showError: true, isDisabled: "", btnText: "Enviar" })
        setTimeout(() => {
          this.toggleHidden()
        }, 5000)
      } else if (changePassword.message === 'passwords.password') {
        this.setState({ errors: "Senha inválida (necessário 8 dígitos)", showError: true, isDisabled: "", btnText: "Enviar" })
        setTimeout(() => {
          this.toggleHidden()
        }, 5000)
      } else {
        this.setState({ errors: "Usuário não encontrado", showError: true, isDisabled: "", btnText: "Enviar" })
        setTimeout(() => {
          this.toggleHidden()
        }, 5000)
      }
    } else {
      this.setState({ errors: "As senhas não são iguais", showError: true, isDisabled: "", btnText: "Enviar" })
      setTimeout(() => {
        this.toggleHidden()
      }, 5000)
    }
  }

  toggleHidden () {
    this.setState({
      showError: !this.state.showError
    })
  }

  render() {

    return (
      <Container>
        <Row style={{display: "initial"}}>
          <form>
            <Col xs={12} offset={{ md: 3 }} md={6}>
              <div className="area-box area-login">
                {this.state.validLogin === false &&
                <React.Fragment>
                  <Col xs={12} md={12}>
                    <label className="label-register"><IoMdHelpCircleOutline /><span>Nova Senha</span><sup className="required-field">*</sup>
                      <input
                        type="password"
                        name="password"
                        onChange={this.onChange}
                        required
                      />
                    </label>
                  </Col>
                  <Col xs={12} md={12}>
                    <label className="label-register"><IoMdHelpCircleOutline /><span>Confirmar Senha</span><sup className="required-field">*</sup>
                      <input
                        type="password"
                        name="password_confirmation"
                        onChange={this.onChange}
                        required
                      />
                    </label>
                  </Col>
                  <Col xs={12} md={12} className="row-btn-submit">
                    <input type="submit" value="Enviar" className="btn-submit"
                      onClick={this.changePassword}
                      style={{
                        minWidth: '270px'
                      }} />
                  </Col>
                  <Col xs={12} md={12} className="row-remember-password">
                      <Link className="remember-password" to="/login">Voltar para o login</Link>
                    </Col>
                </React.Fragment>
                }{this.state.validLogin === true &&
                  <React.Fragment>
                    <Col xs={12} md={12}>
                      <p align="center">Sua senha foi alterada com sucesso!</p>
                    </Col>
                    <Col xs={12} md={12} className="row-remember-password">
                      <Link className="remember-password" to="/login">Voltar para o login</Link>
                    </Col>
                  </React.Fragment>
                }
              </div>
            </Col>
          </form>
        </Row>
        <Col xs={12} md={12} className="row-btn-submit">

          {this.state.errors !== "" && this.state.showError && (<div id="invalid-feedback" className="invalid-feedback" onClick={this.toggleHidden.bind(this)}>{this.state.errors}<span>x</span></div>)}
          
        </Col>
      </Container>
    );
  }
}

export default changePasswordPage;
