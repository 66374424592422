import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';
import BaseLayout from "./BaseLayout";
import LoginPage from "../login/LoginPage";
import RegisterPage from "../register/RegisterPage";
import RegisterOrderPage from "../register/RegisterOrderPage";
import RememberPassword from "../rememberAccess/RememberAccessPage";
import ChangePassword from "../rememberAccess/ChangePasswordPage";
import OrderPage from "../order/OrderPage";
import OrderValidatePage from "../order/OrderValidatePage";
import FinishedPage from "../order/FinishedPage";
import InvoicedPage from "../order/InvoicedPage";
// import WelcomePage from "../welcome/WelcomePage";
import SimpleOrderPage from "../order/SimpleOrderPage";

const Routes = ({ location }) => {
    
    const SessionClient = sessionStorage.menosumlixo_client;
    const SessionAccessToken = sessionStorage.menosumlixo_access_token;
    
    return (
        <BaseLayout >
            {SessionClient && SessionAccessToken ? (
            <Switch location={location} basename={'/'}>
                <Route exact path={`${process.env.PUBLIC_URL}/login`} component={ LoginPage } />
                <Route exact path={`${process.env.PUBLIC_URL}/login/order/:orderID/:token`} component={ LoginPage } />
                <Route exact path="/remember-password" component={ RememberPassword } /> 
                <Route exact path="/change-password" component={ ChangePassword } /> 
                <Route exact path={`${process.env.PUBLIC_URL}/order/finished`} component={ FinishedPage } />
                <Route exact path={`${process.env.PUBLIC_URL}/order/invoiced`} component={ InvoicedPage } />
                <Route exact path={`${process.env.PUBLIC_URL}/order`} component={ OrderPage } />
                <Route exact path={`${process.env.PUBLIC_URL}/order/:orderID/:token`} component={ OrderValidatePage } />
                <Redirect to={`${process.env.PUBLIC_URL}/order`}/>
            </Switch>

            ) : (
                <Switch location={location} basename={'/'}>
                    <Route exact path={`${process.env.PUBLIC_URL}/login`} component={ LoginPage } />
                    <Route exact path={`${process.env.PUBLIC_URL}/login/order/:orderID/:token`} component={ LoginPage } />
                    {/*<Route exact path={`${process.env.PUBLIC_URL}/welcome`} component={ WelcomePage } />*/}
                    <Route exact path={`${process.env.PUBLIC_URL}/order/new`} component={ SimpleOrderPage } /> 
                    <Route exact path={`${process.env.PUBLIC_URL}/order/finished`} component={ FinishedPage } />
                    <Route exact path="/remember-password" component={ RememberPassword } /> 
                    <Route exact path="/password/reset/:token" component={ ChangePassword } /> 
                    <Route exact path={`${process.env.PUBLIC_URL}/register`} component={ RegisterPage } /> 
                    <Route exact path={`${process.env.PUBLIC_URL}/register/order/:orderID/:token`} component={ RegisterOrderPage } /> 
                    <Redirect to={`${process.env.PUBLIC_URL}/order/new`}/>
                </Switch>
            )}
        </BaseLayout>
    );
};

export default withRouter(Routes);