import { PostApi , DeleteApi, PutApi } from '../services/ApiServices';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import * as cep from 'cep-promise'

import { Redirect } from 'react-router-dom';

class OrderPage extends Component {

	constructor(props) {
		super(props);
	
		this.SessionClient = JSON.parse(sessionStorage.menosumlixo_client);
		this.SessionAccessToken = sessionStorage.menosumlixo_access_token;
		this.handleChange = this.handleChange.bind(this);
		this.handleChangePrinting = this.handleChangePrinting.bind(this);
    	this.handleChecked = this.handleChecked.bind(this);
		this.handleChangeCEP = this.handleChangeCEP.bind(this);
		this.setQuantity = this.setQuantity.bind(this);
		this.sign_out = this.sign_out.bind(this);
		this.sendOrder = this.sendOrder.bind(this);

		this.state = {
			products: [],
			printing: 0,
			logo_colors: 0,
			same_address: true,
			user_id: this.SessionClient.id,
			type: this.SessionClient.type,
			address_zipcode: this.SessionClient.address_zipcode,
			address_street: this.SessionClient.address_street,
			address_number: this.SessionClient.address_number,
			address_complement: this.SessionClient.address_complement,
			address_neighborhood: this.SessionClient.address_neighborhood,
			address_state: this.SessionClient.address_state,
			address_city: this.SessionClient.address_city,
			errors: '',
      textBtn: 'Enviar',
      disabled: false,
      showError: false
		}
		window.scrollTo(0, 0);
	}

	handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
	}

	handleChangePrinting(e) {
		if (e.target.value === "0") {
			this.setState({ 
				[e.target.name]: e.target.value,
				logo_colors: ''
			});
		} else {
			this.setState({ [e.target.name]: e.target.value });
		}
	}

	handleChecked (e) {
		if (e.target.checked === true) {
			this.setState({ 
				[e.target.name]: e.target.checked,
				address_zipcode: this.SessionClient.address_zipcode,
				address_street: this.SessionClient.address_street,
				address_number: this.SessionClient.address_number,
				address_complement: this.SessionClient.address_complement,
				address_neighborhood: this.SessionClient.address_neighborhood,
				address_state: this.SessionClient.address_state,
				address_city: this.SessionClient.address_city,
			});
		} else {
			this.setState({ 
				[e.target.name]: e.target.checked,
				address_zipcode: '',
				address_street: '',
				address_number: '',
				address_complement: '',
				address_neighborhood: '',
				address_state: '',
				address_city: '',
			});
		}
	}
	
	async handleChangeCEP(e) {

    this.setState({
			address_zipcode: e.target.value
    });

    if (this.IsCEP(e.target.value)) {
			var cepNumber = e.target.value.replace("-","");
			cepNumber = cepNumber.replace(/\./,"");
			var data = await cep(cepNumber);
			
			this.setState({
				address_street: data.street,
				address_neighborhood: data.neighborhood,
				address_city: data.city,
				address_state: data.state
			});
		}
  }

	IsCEP(strCEP) {
    // Caso o CEP não esteja nesse formato ele é inválido!
    var objER = /^[0-9]{2}[0-9]{3}-[0-9]{3}$/;

    if(strCEP.length > 0) {
      if(objER.test(strCEP))
        return true;
      else
        return false;
    } else
    	return false;
	}

	setQuantity(product) {
		if (product.quantity === "0" && this.state.cart) {
			delete this.state.cart[product.id]
			if (Object.entries(this.state.cart).length === 0 && this.state.cart.constructor === Object) {
				delete this.state.cart
				this.setState({
					...this.state
				})
			} else {
				this.setState({
					cart: {
						...this.state.cart
					}
				})	
			}
		} else {
			this.setState({
				cart: {
					...this.state.cart,
					[product.id] : product.quantity
				}
			})
		}
		
	}

	async componentDidMount() {
		this.mounted = true;
		if (this.mounted) {
		  this.setState({
		    products: await PostApi('products', {})
		  })
		}
	}

	checkMinColorsQuantity() {
		for (var i=0; i<Object.values(this.state.cart).length; i++) {
			if (Object.values(this.state.cart)[i] < 10)
				return true
		}

		return false
	}

	checkMultipleQuantity() {
		for (var i=0; i<Object.values(this.state.cart).length; i++) {
			if (Object.values(this.state.cart)[i] % 10 !== 0)
				return true
		}

		return false
	}

	checkMinQuantity() {
		var quantity = 0

		for (var i=0; i<Object.values(this.state.cart).length; i++) {
			quantity = quantity+parseInt(Object.values(this.state.cart)[i])
		}
		
		if (quantity < 40)
				return true

		return false
	}

	async sign_out() {
		await DeleteApi('logout',sessionStorage.menosumlixo_access_token);
		window.location.href = `${process.env.PUBLIC_URL}/`;
	}

	async sendOrder() {
		const { user_id, printing, logo_colors, address_zipcode, address_street, address_number, address_complement, address_neighborhood, address_state, address_city, type, cart } = this.state

		this.setState({
			disabled: true,
			textBtn: 'Aguarde'
		})

		if (!this.state.cart) {
			this.toTop();
			this.setState({ errors: "A quantidade mínima é de 40 copos", showError: true, disabled: false, textBtn: 'Enviar' })
			setTimeout(() => {
				this.toggleHidden()
			}, 5000)
		} else if (this.checkMinQuantity()) {
			this.toTop();
			this.setState({ errors: "A quantidade mínima é de 40 copos", showError: true, disabled: false, textBtn: 'Enviar' })
			setTimeout(() => {
				this.toggleHidden()
			}, 5000)
		// } else if (this.checkMinColorsQuantity()) {
		// 	this.toTop();
		// 	this.setState({ errors: "A quantidade mínima para cada cor é de 10 copos", showError: true, disabled: false, textBtn: 'Enviar' })
		// 	setTimeout(() => {
		// 		this.toggleHidden()
		// 	}, 5000)
		// } else if (this.checkMultipleQuantity()) {
		// 	this.toTop();
		// 	this.setState({ errors: "A quantidade por cor deve ser múltipla de 10", showError: true, disabled: false, textBtn: 'Enviar' })
		// 	setTimeout(() => {
		// 		this.toggleHidden()
		// 	}, 5000)
		} else if (this.state.printing === "1" && this.state.logo_colors === 0) {
			this.toTop();
			this.setState({ errors: "Escolha o número de cores do logo", showError: true, disabled: false, textBtn: 'Enviar' })
			var focus = document.getElementsByName('logo_colors')
			focus[0].focus();
			setTimeout(() => {
				this.toggleHidden()
			}, 5000)
		} else {
			const data = await PutApi('orders/save', { user_id, printing, logo_colors, address_zipcode, address_street, address_number, address_complement, address_neighborhood, address_state, address_city, type, cart  }, this.SessionAccessToken)

			if (data.code === 201) {
				this.toTop();
				this.props.history.push({
					pathname: '/order/finished',
					order:	1
        }); 
			} else {
				if (data.errors) {
					var keys = Object.keys(data.errors)
					var field = document.getElementsByName(keys[0])
					var message = data.errors[Object.keys(data.errors)[0]]
					field[0].focus();
				}
				this.setState({ errors: message[0], showError: true, disabled: false, textBtn: 'Enviar' })
				setTimeout(() => {
					this.toggleHidden()
				}, 5000)
			}
		}		
  }

  toggleHidden () {
    this.setState({
      showError: !this.state.showError
    })
	}
	
	toTop() {
    window.scrollTo(0, 80);
  }

	render() {
		if (sessionStorage.menosumlixo_access_token) {
			return (
				<React.Fragment>
					<Row className="row-order-area">
						<Col xs={12} sm={10} md={10} lg={10} className="area-box form-order">
							<form encType="multipart/form-data">
								{this.state.products.length > 0 ? (
								<Row id='row-step' className='row-step'>
									<Col xs={12} md={12}>
											<h2>Solicite o seu orçamento</h2>
									</Col>
									<Col xs={12} md={12} style={{marginTop: "30px"}}>
										<p style={{paddingBottom: "30px", textAlign: "center"}}>O nosso pedido mínimo é de 40 unidades, ok??<br /><small>Caso deseje menos de 40 unidades, finalize seu pedido com a quantidade mínima (40 unid.) e após receber seu orçamento converse com nossa equipe comercial.</small></p>
										<Row>
											{this.state.products.map((products, i) => {
												return <ProductItem key={i} item={products} id={i} setQuantity={this.setQuantity} />;
											})}
										</Row>
									</Col>
									<Col xs={12} md={12}>
										<label className="label-register"><span>É personalizado?</span><br />
										<small style={{color: "#fa5872"}}>A personalização acontece na superfície externa da tampa</small>
										<select
											name="printing"
											ref={this.printing}
											value={this.state.printing || ''}
											onChange={this.handleChangePrinting}
										>
											<option value={0}>Não</option>
											<option value={1}>Sim</option>
										</select>
										</label>
									</Col>
									{this.state.printing === "1" && (
										<Col xs={12} md={12}>
											<label className="label-register"><span>Quantas cores tem o logo?</span>
												<select
													name="logo_colors"
													ref={this.logo_colors}
													value={this.state.logo_colors || ''}
													onChange={this.handleChange}
												>
													<option value={0}>Selecione a resposta</option>
													<option value={1}>1</option>
													<option value={2}>2</option>
													<option value={3}>3</option>
													<option value={4}>4</option>
												</select>
											</label>

											<p><strong>Está com dúvida como escolher a opção correta para sua logo? A gente te ajuda!</strong></p>
											<ul>
												<li>- Importante: não trabalhamos com  degradê, as cores da logo devem ser “chapadas” - que é quando a cor tem tonalidade única, sem qualquer variação. </li>
												<li>- Se a sua logo tiver mais de 4 cores (ser colorida), optar por 4 cores.</li>
											</ul>
										</Col>
									)}
									{/* <Col xs={12} md={12}>
										<label className="label-register"><span>Endereço de faturamento é igual ao endereço cadastrado da empresa</span>
										<input
											className="input-radio"
											name="same_address"
											type="checkbox"
											readOnly
											checked={this.state.same_address === false ? false : true}
											onChange={this.handleChecked}
											style={{marginTop: "0px"}}
										/>
										</label>
									</Col> */}
									{this.state.same_address === false && (
										<Col xs={12} md={12}>
											<Col xs={12} md={12}>
												<h3>Endereço</h3>
											</Col>
											<Row>
												<Col xs={12} md={6}>
														<label className="label-register"><span>CEP</span><sup className="required-field">*</sup>
														<InputMask
																name="address_zipcode"
																type="text"
																mask="99999-999"
																ref={this.address_zipcode}
																required={true}
																value={this.state.address_zipcode || ''}
																onChange={this.handleChangeCEP}
														/>
														</label>
												</Col>
												<Col xs={12} md={6}>
														<label className="label-register"><span>Rua</span><sup className="required-field">*</sup>
														<input
																name="address_street"
																type="text"
																ref={this.address_street}
																required={true}
																value={this.state.address_street || ''}
																onChange={this.handleChange}
														/>
														</label>
												</Col>
												<Col xs={12} md={6}>
														<label className="label-register"><span>Número</span><sup className="required-field">*</sup>
														<input
																name="address_number"
																type="text"
																ref={this.address_number}
																required={true}
																value={this.state.address_number || ''}
																onChange={this.handleChange}
														/>
														</label>
												</Col>
												<Col xs={12} md={6}>
														<label className="label-register"><span>Complemento</span>
														<input
																name="address_complement"
																type="text"
																ref={this.address_complement}
																value={this.state.address_complement || ''}
																onChange={this.handleChange}
														/>
														</label>
												</Col>
												<Col xs={12} md={6}>
														<label className="label-register"><span>Bairro</span><sup className="required-field">*</sup>
														<input
																name="address_neighborhood"
																type="text"
																ref={this.address_neighborhood}
																required={true}
																value={this.state.address_neighborhood || ''}
																onChange={this.handleChange}
														/>
														</label>
												</Col>
												<Col xs={12} md={6}>
														<label className="label-register"><span>Estado</span><sup className="required-field">*</sup>
														<select 
														name="address_state"
														type="text"
														ref={this.address_state}
														required={true}
														value={this.state.address_state || ''}
														onChange={this.handleChange}
														>
															<option value="">Escolha um estado</option>
															<option value="AC">Acre</option>
															<option value="AL">Alagoas</option>
															<option value="AP">Amapá</option>
															<option value="AM">Amazonas</option>
															<option value="BA">Bahia</option>
															<option value="CE">Ceará</option>
															<option value="DF">Distrito Federal</option>
															<option value="ES">Espírito Santo</option>
															<option value="GO">Goiás</option>
															<option value="MA">Maranhão</option>
															<option value="MT">Mato Grosso</option>
															<option value="MS">Mato Grosso do Sul</option>
															<option value="MG">Minas Gerais</option>
															<option value="PA">Pará</option>
															<option value="PB">Paraíba</option>
															<option value="PR">Paraná</option>
															<option value="PE">Pernambuco</option>
															<option value="PI">Piauí</option>
															<option value="RJ">Rio de Janeiro</option>
															<option value="RN">Rio Grande do Norte</option>
															<option value="RS">Rio Grande do Sul</option>
															<option value="RO">Rondônia</option>
															<option value="RR">Roraima</option>
															<option value="SC">Santa Catarina</option>
															<option value="SP">São Paulo</option>
															<option value="SE">Sergipe</option>
															<option value="TO">Tocantins</option>
															<option value="ES">Estrangeiro</option>
														</select>
														</label>
												</Col>
												<Col xs={12} md={6}>
														<label className="label-register"><span>Cidade</span><sup className="required-field">*</sup>
														<input
																name="address_city"
																type="text"
																ref={this.address_city}
																required={true}
																value={this.state.address_city || ''}
																onChange={this.handleChange}
														/>
														</label>
												</Col>
											</Row>
										</Col>
										)}
									<Col xs={12} md={12}>
											<Link to="#" onClick={this.sign_out} className="btn-submit btn-logout">Sair</Link> 
											<input
											className="btn-submit"
											type="button"
											value={this.state.textBtn}
											disabled={this.state.disabled}
											onClick={() => this.sendOrder()}
											/>
									</Col>
									<Col xs={12} md={12} className="row-btn-submit">

										{this.state.errors !== "" && this.state.showError && (<div id="invalid-feedback" className="invalid-feedback">{this.state.errors}</div>)}
										
									</Col>
									
								</Row>
								) : (
									<p style={{textAlign: "center"}}>Carregando...</p>
								)}
							</form>
						</Col>
					</Row>
				</React.Fragment>
			);
		} else {
				return (<Redirect to={'/login'} />)
		}
	}
}

class ProductItem extends Component {
	
	constructor(props) {
		super(props);
		this.SessionClient = JSON.parse(sessionStorage.menosumlixo_client);
		this.handleChange = this.handleChange.bind(this);

		this.state = {
			id: this.props.item.id,
			quantity: 0
		}
		
	}

	async handleChange(e) {
		if (e.target.value === "") {
			e.target.value = 0
			await this.setState({
				[e.target.name]: e.target.value 
			}); 
			this.props.setQuantity(this.state);
		} else {
			e.target.value = e.target.value.replace(/^0+/, '')
			if (e.target.value === "") {
				e.target.value = 0
				await this.setState({
					[e.target.name]: e.target.value 
				});
				this.props.setQuantity(this.state);
			} else {
				await this.setState({
					[e.target.name]: e.target.value 
				}); 
				this.props.setQuantity(this.state);
			}
		}
	}

	render() {
		return(
			<Col xs={12} md={6}>
				<Row>
					<Col xs={6} md={6} style={{textAlign: "center"}}>
						<img src={process.env.REACT_APP_URL+'img/'+this.props.item.name+'.jpg'} alt='' style={{maxWidth: "120px"}} />
					</Col>
					<Col xs={6} md={6}>
						<label className="label-register"><span>{this.props.item.name}</span>
						
						<p>Qtde: <input
								name="quantity"
								type="number"
								min={0}
								required={true}
								value={this.state.quantity}
								onChange={this.handleChange}
						/></p>
						</label>
					</Col>
				</Row>
			</Col>
		)
	}
}

export default OrderPage;
