
import { Container, Row, Col } from 'react-grid-system';
import React, { Component } from 'react';
import LogoCopyright from '../images/logo.png';

class Footer extends Component {
    render() {
        
        return (
            <Col xs={12} md={12}>
                <Container>
                    <Row className="inner-footer">
                        <Col xs={12} md={6} className="footer-first-col">
                            <p>© <strong>Menos Um Lixo</strong> - Todos os direitos reservados.</p>
                        </Col>
                        <Col xs={12} md={6} className="footer-last-col">
                            <p>
                                <a href="http://menosumlixo.com.br" alt="" target="blank">
                                    <img src={LogoCopyright} alt="" />
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Container>

            </Col>
        );
    }
}

export default Footer;