import { DeleteApi } from "../services/ApiServices";
import { Link } from "react-router-dom";
import { Row, Col } from "react-grid-system";
import React, { Component } from "react";

class OrderPage extends Component {
  constructor(props) {
    super(props);

    if (!this.props.history.location.order) {
      this.props.history.push({
        pathname: "/",
      });
    }

    if (
      sessionStorage.menosumlixo_client &&
      sessionStorage.menosumlixo_access_token
    ) {
      this.SessionClient = JSON.parse(sessionStorage.menosumlixo_client);
      this.SessionAccessToken = sessionStorage.menosumlixo_access_token;
    }

    this.state = {};
    window.scrollTo(0, 0);
  }

  async sign_out() {
    await DeleteApi("logout", sessionStorage.menosumlixo_access_token);
    window.location.href = `${process.env.PUBLIC_URL}/`;
  }

  render() {
    return (
      <React.Fragment>
        <Row className="row-order-area">
          <Col xs={12} sm={10} md={10} lg={10} className="area-box form-order">
            <form encType="multipart/form-data">
              <Row id="row-step" className="row-step">
                <Col xs={12} md={12} style={{ textAlign: "center" }}>
                  <h2>
                    Muito bem vind@ ao Menos 1 Lixo , você vai receber o
                    orçamento solicitado em instantes! ;)
                  </h2>
                </Col>
                <Col xs={12} md={12} style={{ marginTop: "30px" }}>
                  {/* <p style={{ paddingBottom: '30px', textAlign: 'center' }}>
                    {' '}
                    ...para fechar o pedido é só clicar em “fazer pedido” ,
                    entraremos em contato somente para alinharmos os detalhes!
                  </p> */}
                  <p style={{ paddingBottom: "30px", textAlign: "center" }}>
                  Por aqui a nossa missão é estimular o empoderamento de indivíduos e organizações para alcançarmos uma forma mais sustentável de viver em sociedade! Vai ser maravilhoso ter você com a gente nessa missão.
                  </p>
                  <p style={{ paddingBottom: "30px", textAlign: "center" }}>
                  Em caso de dúvidas, pode escrever para nosso time comercial por email ou whatsapp.
                  </p>
                  {/* <hr />
                  <h3 style={{ paddingTop: "20px", paddingBottom: "20px", textAlign: "center" }}>
                    Obrigada por solicitar o seu orçamento.
                  </h3>
                  <p style={{ paddingBottom: "30px", textAlign: "center" }}>
                    Queremos te contar que estamos com uma <strong>MEGA PROMOÇÃO</strong> pra Semana do Meio Ambiente.
                  </p>
                  <p style={{ paddingBottom: "30px", textAlign: "center" }}>
                    Em instantes você receberá seu orçamento por email, mas em seguida uma de nossas consultoras entrará em contato para te passar os <strong>valores especiais com o desconto aplicado</strong>!
                  </p>
                  <p style={{ paddingBottom: "30px", textAlign: "center" }}>
                    Quer antecipar essa conversa? Pode chamar no whatsapp <a href="https://bit.ly/relacionamentoM1L" target={"_blank"}>clicando aqui</a> ou através do número (21) 97925-2086.
                  </p> */}
                  {/* <p style={{ paddingBottom: "30px", textAlign: "center" }}>
                  Estamos preparando seu orçamento com muito carinho e em instantes ele chegará pra você por e-mail, tá? Prometo que não demora. ;) Hahah<br />Qualquer dúvida, pode escrever para nosso time comercial.
                  </p> */}
                  <p style={{ paddingBottom: "30px", textAlign: "center" }}>
                    Se você é Empresa: <a href="mailto:comercial@menos1lixo.com.br" title="Enviar e-mail para comercial@menos1lixo.com.br">comercial@menos1lixo.com.br</a> ou chama no número <a href="https://api.whatsapp.com/send?phone=5521999439101" target="_blank">(21) 99943-9101</a><br />
                    E se for PDV: <a href="mailto:atendimento@menos1lixo.com.br" title="Enviar e-mail para comercial@menos1lixo.com.br">atendimento@menos1lixo.com.br</a> ou no número <a href="https://api.whatsapp.com/send?phone=5521971794529" target="_blank">(21) 97179-4529</a>
                  </p>
                  <p style={{ paddingBottom: "30px", textAlign: "center" }}>
                    {/* Obrigada pelo contato, já já nos falamos! */}
                    Até logo! :)
                  </p>
                </Col>
                <Col xs={12} md={12}>
                  {sessionStorage.menosumlixo_access_token ? (
                    <Link
                      to="#"
                      onClick={this.sign_out}
                      className="btn-submit btn-logout"
                    >
                      Sair
                    </Link>
                  ) : (
                    <Link to="welcome" className="btn-submit btn-logout">
                      Voltar
                    </Link>
                  )}
                  {/* <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    className="btn-submit"
                  >
                    Fazer outro pedido
                  </Link> */}
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default OrderPage;
