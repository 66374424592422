import axios from 'axios';

async function PutApi(type, apiData, accessToken) {
  const ApiUrl = process.env.REACT_APP_API_URL + type;
  var headers;
  if (accessToken) {
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken
    };
  } else {
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
  }

  const { data } = await axios
    .put(ApiUrl, apiData, {
      headers: headers
    })
    .catch(function(error) {
      if (error.response) {
        return error.response;
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    });

  return data;
}
export default PutApi;
