import axios from 'axios';

async function PostApi(type, apiData) {

    const ApiUrl = process.env.REACT_APP_API_URL + type;

    const { data } = await axios.post(ApiUrl,apiData)
    .catch(function (error) {
      console.log(error)
      if (error.response) {
        return error.response
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    })
    
    return data

}
export default PostApi;

